import React from 'react';
import { Image } from '@nextui-org/react';
import { Tooltip } from '@nextui-org/react';

const ACCOLADE_BASE = '/images/accolades/';
const RANK_BASE = '/images/ranks/';

export const ACCOLADE_MAP = {
  "Dizana's quiver (uncharged)": `${ACCOLADE_BASE}dizanas_quiver_uncharged.png`,
  'Infernal cape': `${ACCOLADE_BASE}infernal_cape.png`,
  'Max Total': `${ACCOLADE_BASE}max_cape.png`,
  Ironman: `${ACCOLADE_BASE}iron_helm.png`,
  'Ultimate Ironman': `${ACCOLADE_BASE}uim_helm.png`,
  'Hardcore Ironman': `${ACCOLADE_BASE}hardcore_iron_helm.png`,
  'Group Ironman': `${ACCOLADE_BASE}group_iron_helm.png`,
  'Hardcore Group Ironman': `${ACCOLADE_BASE}hardcore_group_iron_helm.png`,
  'Unranked Group Ironman': `${ACCOLADE_BASE}unranked_group_iron_helm.png`,
  Beast: `${RANK_BASE}Clan_icon_-_Beast.png`,
  Dragon: `${RANK_BASE}Clan_icon_-_Paladin.png`,
  Rune: `${RANK_BASE}Clan_icon_-_Knight.png`,
  Adamant: `${RANK_BASE}Clan_icon_-_Expert.png`,
  Mithril: `${RANK_BASE}Clan_icon_-_Inquisitor.png`,
  Steel: `${RANK_BASE}Clan_icon_-_Striker.png`,
  Iron: `${RANK_BASE}Clan_icon_-_Duellist.png`,
  Bronze: `${RANK_BASE}Clan_icon_-_Squire.png`
};

const Accolade = ({ accoladeKey, ...propOverride }) => {
  const image = ACCOLADE_MAP[accoladeKey];

  return (
    <Tooltip content={accoladeKey}>
      <Image
        src={image}
        radius="none"
        width={24}
        height={24}
        alt={accoladeKey}
        {...propOverride}
      />
    </Tooltip>
  );
};

export default Accolade;
