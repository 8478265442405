import React from 'react';
import { useUser } from '../../../hooks/use-user.js';
import * as flags from 'country-flag-icons/react/3x2';

const CountryFlag = () => {
  const { member } = useUser();

  const ReactCountryFlag = flags[member.countryCode];
  if (!ReactCountryFlag) return null;

  return <ReactCountryFlag className="h-4 w-6" title={member.country} />;
};

export default CountryFlag;
