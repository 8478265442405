import React from 'react';

import {
  Navbar,
  NavbarContent,
  NavbarItem,
  Link,
  NavbarBrand
} from '@nextui-org/react';

const generateTab = (label, path, props = {}) => {
  const isActive = window.location.pathname === path;
  const linkProps = {
    href: path,
    'aria-current': isActive && 'page',
    ...props,
    color: isActive ? 'inherit' : 'foreground'
  };
  return (
    <NavbarItem isActive={isActive}>
      <Link {...linkProps}>{label}</Link>
    </NavbarItem>
  );
};

export const Nav = () => {
  return (
    <Navbar className="bg-[#0c0c0c]" position="static" isBordered>
      <NavbarBrand>
        <p className="font-bold text-inherit"></p>
      </NavbarBrand>
      <NavbarContent className=" sm:flex gap-4" justify="center">
        {generateTab('Home', '/')}
        {generateTab('Leaderboard', '/leaderboard')}
        {generateTab('Points', '/points')}
        {generateTab('Discord', '/discord', { showAnchorIcon: true })}
      </NavbarContent>
      <NavbarContent justify="end">
        <NavbarItem className="hidden lg:flex">
          <Link href="#"></Link>
        </NavbarItem>
      </NavbarContent>
    </Navbar>
  );
};

export default Nav;
