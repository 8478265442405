import React, { createContext, useState, useContext } from 'react';

// Step 1: Create a new context
const UserContext = createContext();

// Step 2: Define a provider component for this context
export function UserProvider({ children }) {
  // Step 3: Initialize state for the account data
  const [account, setAccount] = useState(null);
  const [member, setMember] = useState(null);
  const [pointHistories, setPointHistories] = useState([]);
  const [leaderboardRank, setLeaderboardRank] = useState({});

  const context = {
    account,
    setAccount,
    member,
    setMember,
    pointHistories,
    setPointHistories,
    leaderboardRank,
    setLeaderboardRank
  };

  // Step 4: Provide the account data and a function to update it
  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
}

// Step 5: Define a custom hook that provides access to the account context
export function useUser() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useAccount must be used within an AccountProvider');
  }
  return context;
}
