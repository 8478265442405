import React from 'react';
import { Avatar } from '@nextui-org/react';

const RsnField = ({ account }) => {
  return (
    <div className="flex flex-row items-center">
      <div className="w-8 h-8 mr-2">
        <Avatar
          src={account.avatarUrl}
          className={`w-8 h-8 text-large`}
          showFallback
        />
      </div>
      <div>{account.rsn}</div>
    </div>
  );
};

export default RsnField;
