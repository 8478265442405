import React from 'react';
import CountryFlag from './accolades/CountryFlag.js';
import { Avatar, Badge } from '@nextui-org/react';

const UserAvatar = ({ account }) => {
  return (
    <Badge
      isDot
      showOutline={false}
      variant="flat"
      className={`rounded-none p-0  translate-y-1/6 translate-x-1/4`}
      shape="rectangle"
      placement="bottom-right"
      color="warning"
      content={<CountryFlag />}
    >
      <Avatar
        src={account.avatarUrl}
        className={`w-20 h-20 text-large  ${account.rank?.name}`}
        showFallback
      />
    </Badge>
  );
};

export default UserAvatar;
