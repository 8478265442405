import React from 'react';
import PropTypes from 'prop-types';

import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  getKeyValue,
  Spinner,
  Pagination,
  Input
} from '@nextui-org/react';
import classNames from 'classnames';

import { SearchIcon } from '../../components/Icons/SearchIcon.js';

const SortTable = ({
  records = [],
  columns = [],
  title = '',
  recordsPerPage = 15,
  isLoading = false,
  isSearchable = false,
  defaultSort = {},
  searchableField = '',
  className = '',
  tableClassNameOverrides = {},
  ...tableProps
}) => {
  const [filterValue, setFilterValue] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [sortDescriptor, setSortDescriptor] = React.useState(defaultSort);
  const hasSearchFilter = Boolean(filterValue);

  const filteredItems = React.useMemo(() => {
    if (hasSearchFilter) {
      return records.filter((record) =>
        record[searchableField]
          .toLowerCase()
          .includes(filterValue.toLowerCase())
      );
    }
    return records;
  }, [records, filterValue, hasSearchFilter, searchableField]);

  const sortedItems = React.useMemo(() => {
    const sortedList = [...filteredItems].sort((a, b) => {
      const first = a[sortDescriptor.column];
      const second = b[sortDescriptor.column];
      const cmp = first < second ? -1 : first > second ? 1 : 0;

      return sortDescriptor.direction === 'descending' ? -cmp : cmp;
    });
    return sortedList.map((item, i) => ({ ...item, index: i + 1 }));
  }, [sortDescriptor, filteredItems]);

  const paginatedRows = React.useMemo(() => {
    const start = (page - 1) * recordsPerPage;
    const end = start + recordsPerPage;

    return sortedItems.slice(start, end);
  }, [page, sortedItems, recordsPerPage]);

  const pages = Math.max(Math.ceil(filteredItems?.length / recordsPerPage), 1);

  const generateColumns = () => {
    return columns.map((column) => (
      <TableColumn
        key={column.key}
        {...column.props}
        className={classNames(
          sortDescriptor.column === column.key ? 'text-primary' : '',
          column.className
        )}
      >
        {column.label}
      </TableColumn>
    ));
  };

  const onSearchChange = React.useCallback((value) => {
    if (value) {
      setFilterValue(value);
      setPage(1);
    } else {
      setFilterValue('');
    }
  }, []);

  const renderCell = React.useCallback(
    (item, columnKey) => {
      const columnConfig = columns.find((c) => c.key === columnKey);
      return columnConfig?.render
        ? columnConfig.render(item)
        : getKeyValue(item, columnKey);
    },
    [columns]
  );

  return (
    <div className={`flex flex-col w-full ${className}`}>
      {title && (
        <span className="text-primary text-small text-center">{title}</span>
      )}

      <Table
        isCompact
        isStriped
        aria-label="Table with client side sorting"
        sortDescriptor={sortDescriptor}
        onSortChange={setSortDescriptor}
        classNames={{
          th: '!rounded-sm',
          td: 'font-extralight last:before:rounded-r-sm first:before:rounded-l-sm',
          tr: [tableProps.selectionMode ? 'cursor-pointer' : ''],
          ...tableClassNameOverrides
        }}
        topContent={
          isSearchable && (
            <Input
              isClearable
              classNames={{
                base: 'w-full h-1/3 ',
                inputWrapper: 'border-1 h-1/3 text-xs rounded-xl',
                input: 'text-xs'
              }}
              placeholder={`Search by ${searchableField}...`}
              size="sm"
              startContent={<SearchIcon className="text-default-300" />}
              value={filterValue}
              variant="bordered"
              onClear={() => setFilterValue('')}
              onValueChange={onSearchChange}
            />
          )
        }
        bottomContent={
          <div className="flex w-full justify-center items-end">
            <Pagination
              showControls
              showShadow
              color="primary"
              page={page}
              total={pages}
              onChange={(page) => setPage(page)}
              variant="flat"
            />
          </div>
        }
        bottomContentPlacement="outside"
        {...tableProps}
      >
        <TableHeader>{generateColumns()}</TableHeader>
        <TableBody
          items={paginatedRows || []}
          isLoading={isLoading}
          loadingContent={<Spinner label="Loading..." />}
        >
          {(item) => (
            <TableRow key={item.id}>
              {(columnKey) => (
                <TableCell>{renderCell(item, columnKey)}</TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

SortTable.propTypes = {
  records: PropTypes.array,
  columns: PropTypes.array,
  recordsPerPage: PropTypes.number,
  isLoading: PropTypes.bool
};

export default SortTable;
