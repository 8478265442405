import React from 'react';

import { startCase } from 'lodash';

import { Card, CardHeader, CardBody, Divider } from '@nextui-org/react';

import { useAsyncList } from '@react-stately/data';
import SortTable from '../../components/SortTable/SortTable.js';

const RECORDS_PER_PAGE = 15;
const COLUMNS = [
  {
    key: 'name',
    props: {
      allowsSorting: true
    },
    label: 'Point Source Name'
  },
  {
    key: 'points',
    props: {
      allowsSorting: true
    },
    label: 'Points Awarded'
  },
  {
    key: 'category',
    props: {
      allowsSorting: true
    },
    label: 'Category'
  }
];

export const Points = () => {
  const [isLoading, setIsLoading] = React.useState(true);

  const list = useAsyncList({
    async load({ signal }) {
      const res = await fetch('/api/point-sources', {
        signal
      });
      const json = await res.json();
      const items = json.map((item) => ({
        ...item,
        category: startCase(item.category.toLowerCase())
      }));
      setIsLoading(false);
      return {
        items
      };
    }
  });

  const totalPoints = React.useMemo(() => {
    return list.items
      .filter((i) => i.category === 'Account')
      .reduce((acc, item) => acc + item.points, 0);
  }, [list]);

  return (
    <div className="flex justify-center mt-4">
      <div className="flex flex-col justify-center lg:w-3/4 sm:w-full">
        <SortTable
          columns={COLUMNS}
          records={list.items}
          recordsPerPage={RECORDS_PER_PAGE}
          isLoading={isLoading}
          isSearchable={true}
          searchableField="name"
          title="All Point Sources (Community and Account)"
          defaultSort={{ column: 'name', direction: 'ascending' }}
        />
        <div className="flex flex-col mt-5 font-extralight">
          <Card className="m-1">
            <CardHeader>Points Summary</CardHeader>
            <Divider />
            <CardBody>
              <div className="flex flex-col space-y-2">
                <div className="flex justify-between">
                  <span>
                    The total number of account points possible is{' '}
                    <span className="text-primary">{totalPoints}</span>.
                    Community points are not included in this total as community
                    point sources are able to be earned multiple times.
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card className="m-1">
            <CardHeader>Ranking System</CardHeader>
            <Divider />
            <CardBody>
              <div className="flex flex-col space-y-2">
                <div className="flex justify-between">
                  <span>
                    Requirements for each ranking tier can be found in our
                    Discord. A combination of account points and community
                    points are required to reach each tier. Community points are
                    earned through participation in community events and
                    activities. Account points are earned through the
                    acquisition of items and levels.
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Points;
