import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import AccountAccolades from './accolades/AccountAccolades.js';
import Accolade from './accolades/Accolade.js';
import './ProfileUserInfo.css';
import UserAvatar from './UserAvatar.js';

export const ProfileUserInfo = ({ account = {} }) => {
  const formatDate = (date) => {
    return moment(date).format('MMM Do, YYYY');
  };

  return (
    <div className="flex flex-row mb-2 items-center">
      <div className="w-20 h-20">
        <UserAvatar account={account} />
      </div>
      <div className="flex flex-col space-between ml-5 mt-4">
        <div className="flex flex-col item-start lg:flex-row lg:items-end">
          <h1 className="text-3xl font-bold pr-2">{account.rsn}</h1>
          <div className="pb-1">
            <AccountAccolades />
          </div>
        </div>
        <div className="flex flex-row items-center">
          <div className="mr-1">
            <Accolade
              accoladeKey={account.accountType}
              width={16}
              height={16}
            />
          </div>
          <div className="font-extralight">
            {account.accountType ? `${account.accountType} - ` : ''} Joined{' '}
            {formatDate(account.createdAt)}
          </div>
        </div>
      </div>
    </div>
  );
};

ProfileUserInfo.propTypes = {
  member: PropTypes.object,
  account: PropTypes.object
};

export default ProfileUserInfo;
