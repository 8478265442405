import React from 'react';
import Leaderboard from '../Leaderboard/Leaderboard.js';
import { Button } from '@nextui-org/react';
import './LandingPage.css';

export const LandingPage = () => {
  const [totalExp, setTotalExp] = React.useState(0);
  const [totalEhp, setTotalEhp] = React.useState(0);
  const [totalEhb, setTotalEhb] = React.useState(0);
  const [memberCount, setMemberCount] = React.useState(0);

  const computeTotals = React.useCallback((data) => {
    const { exp, ehp, ehb } = data.memberships?.reduce(
      (acc, { player }) => {
        acc.exp += player.exp;
        acc.ehp += player.ehp;
        acc.ehb += player.ehb;
        return acc;
      },
      {
        exp: 0,
        ehp: 0,
        ehb: 0
      }
    );
    setTotalExp(exp.toLocaleString());
    setTotalEhb(ehb.toLocaleString());
    setTotalEhp(ehp.toLocaleString());
    setMemberCount(data.memberCount);
  }, []);

  React.useEffect(() => {
    fetch('/api/landing')
      .then((res) => res.json())
      .then(computeTotals)
      .catch((err) => console.error(err));
  }, [computeTotals]);

  return (
    <>
      <div className="flex flex-col min-h-[100dvh]">
        <main className="flex-1">
          <section className="w-full pt-10 pb-12 bg-[#0c0c0c] text-white justify-center flex">
            <div className="container px-6">
              <div className="lg:grid-cols-[1fr_400px] lg:gap-12 xl:grid-cols-[1fr_600px]">
                <div className="flex flex-col justify-center space-y-4">
                  <div className="space-y-2">
                    <h1 className="text-primary text-3xl primary font-bold tracking-tighter sm:text-5xl xl:text-6xl/none">
                      Embargo
                    </h1>
                    <p className="max-w-[600px] text-muted-foreground md:text-xl">
                      An ironman only clan with a fully custom ranking system, a
                      runelite plugin, profile pages, a leaderboard, and much
                      much more.
                    </p>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="bg-[#1a1a1a] rounded-lg p-4 text-center border border-zinc-700">
                      <h3 className="text-2xl font-bold">{memberCount}</h3>
                      <p className="text-primary text-sm text-muted-foreground">
                        Total Members
                      </p>
                    </div>
                    <div className="bg-[#1a1a1a] rounded-lg p-4 text-center border border-zinc-700">
                      <h3 className="text-2xl font-bold">{totalExp}</h3>
                      <p className="text-primary text-sm text-muted-foreground">
                        Total Combined Exp
                      </p>
                    </div>
                    <div className="bg-[#1a1a1a] rounded-lg p-4 text-center border border-zinc-700">
                      <h3 className="text-2xl font-bold">{totalEhp}</h3>
                      <p className="text-primary text-sm text-muted-foreground">
                        Combined EHP
                      </p>
                    </div>
                    <div className="bg-[#1a1a1a] rounded-lg p-4 text-center border border-zinc-700">
                      <h3 className="text-2xl font-bold">{totalEhb}</h3>
                      <p className="text-primary text-sm text-muted-foreground">
                        Combined EHB
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="w-full py-12 bg-[#1a1a1a] text-white justify-center flex">
            <div className="container px-6">
              <div className="flex flex-col items-center justify-center space-y-4 text-center">
                <div className="space-y-2">
                  <h2 className="text-primary text-3xl font-bold tracking-tighter sm:text-5xl">
                    A Clan For All Irons
                  </h2>
                  <p className="max-w-[900px] text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                    Whether you're an early, mid-game, or end-game ironman,
                    Embargo has a place for you. We offer a variety of events,
                    competitions, and resources to help you progress in your
                    ironman journey.
                  </p>
                  <p className="max-w-[900px] text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                    Interested in becoming a member? Click the button below to
                    join our Discord!
                  </p>
                  <Button>
                    <a href="/discord" target="_blank">
                      Join Discord
                    </a>
                  </Button>
                </div>
                <div className="flex flex-col gap-2 min-[400px]:flex-row"></div>
              </div>
            </div>
          </section>
          <section className="w-full pt-12 pb-1 bg-[#0c0c0c] text-white justify-center flex">
            <div className="container px-6">
              <div className="flex flex-col items-center justify-center space-y-4 text-center">
                <div className="space-y-2">
                  <h2 className="text-primary text-3xl font-bold tracking-tighter sm:text-5xl py-4">
                    Clan Leaderboard
                  </h2>
                  <p className="max-w-[900px] text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                    Check out the top players in our clan based on our custom
                    ranking system (Embargo Points). Click on a user to see
                    their profile page.
                  </p>
                  <Leaderboard />
                </div>
              </div>
            </div>
          </section>
        </main>
        <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-6 bg-[#0c0c0c] text-white">
          <p className="text-xs text-muted-foreground">
            &copy; 2024 Embargo. All rights reserved.
          </p>
          <nav className="sm:ml-auto flex gap-4 sm:gap-6"></nav>
        </footer>
      </div>
    </>
  );
};

export default LandingPage;
