import React, { useMemo } from 'react';
import SortTable from '../SortTable/SortTable.js';
import moment from 'moment';

const RECORDS_PER_PAGE = 10;

const COLUMNS = [
  {
    key: 'name',
    props: {
      allowsSorting: true
    },
    label: 'Point Source Name',
    className: 'w-3/6'
  },
  {
    key: 'points',
    props: {
      allowsSorting: true
    },
    label: 'Points Awarded',
    className: 'w-1/6'
  },
  {
    key: 'createdAt',
    props: {
      allowsSorting: true
    },
    label: 'Date Achieved',
    className: 'w-2/6'
  }
];

export const PointHistoryTables = ({ pointHistories, isLoading }) => {
  const formattedHistories = useMemo(() => {
    return pointHistories.map((history) => ({
      id: history.id,
      name: history.pointSource?.name,
      points: history.pointSource?.points,
      category: history.category,
      createdAt: moment(history.createdAt).format('YYYY-MM-DD')
    }));
  }, [pointHistories]);

  const accountHistories = useMemo(() => {
    return formattedHistories.filter(
      (history) => history.category === 'ACCOUNT'
    );
  }, [formattedHistories]);

  const communityHistories = useMemo(() => {
    return formattedHistories.filter(
      (history) => history.category === 'COMMUNITY'
    );
  }, [formattedHistories]);
  console.log('communityHistories length:', communityHistories.length);

  return (
    <>
      <SortTable
        columns={COLUMNS}
        records={accountHistories}
        recordsPerPage={RECORDS_PER_PAGE}
        isLoading={isLoading}
        isSearchable={true}
        searchableField="name"
        title="Account Points"
        defaultSort={{ column: 'createdAt', direction: 'descending' }}
        className="mt-2 min-h-96 2xl:mr-2"
      />
      <SortTable
        columns={COLUMNS}
        records={communityHistories}
        recordsPerPage={RECORDS_PER_PAGE}
        isLoading={isLoading}
        isSearchable={true}
        searchableField="name"
        title="Community Points"
        defaultSort={{ column: 'createdAt', direction: 'descending' }}
        className="mt-2 min-h-96"
      />
    </>
  );
};

export default PointHistoryTables;
