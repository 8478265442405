import React, { useMemo } from 'react';
import { useUser } from '../../../hooks/use-user.js';
import Accolade, { ACCOLADE_MAP } from './Accolade.js';

export const AccountAccolades = () => {
  const { account, pointHistories } = useUser();

  const accolades = useMemo(() => {
    if (!account) {
      return [];
    }
    const ph = pointHistories
      .filter((h) => ACCOLADE_MAP[h.pointSource.name])
      .map((h) => h.pointSource.name);
    return [account.rank.name, ...ph];
  }, [pointHistories, account]);

  return (
    <div className="flex flex-row">
      {}
      {accolades.map((accolade) => (
        <Accolade key={accolade} accoladeKey={accolade} />
      ))}
    </div>
  );
};

export default AccountAccolades;
